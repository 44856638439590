/** Takes Doe, John and returns John Doe */
export function reverseName(name) {
  if (name.includes(',')) {
    return name.split(',').reverse().join(' ');
  } else {
    return name;
  }
}

export function addSemicolonToEnd(string: String) {
  if (string) {
    if (!string.endsWith(';')) {
      return string + ';';
    } else {
      return string;
    }
  } else {
    return null;
  }
}

export function removeSemicolonFromEnd(string: String) {
  if (string) {
    if (string.endsWith(';')) {
      return string.trim().slice(0, -1);
    } else {
      return string;
    }
  } else {
    return null;
  }
}

export function toBase64(input: string): string {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  let binary = '';
  const len = data.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(data[i]);
  }
  return btoa(binary);
}

