// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Rxjs
import { Subject } from 'rxjs';
import { takeUntil, map, filter } from 'rxjs/operators';

// Services
// import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/services/authentication/auth/auth.service';
import { FeatureToggleService } from './core/services/feature-toggle/feature-toggle.service';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { MsalLoginRedirectService } from './core/services/msal/msal-login-redirect.service';
import { NpsFeedbackService } from '@cham-services/nps-feedback/nps-feedback.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private _user = null;
  private _roles: any;
  public isUserLoggedIn: any;
  public userProfileDetails: any;
  public sus_nps_validation: any;

  public openDrawer: any;
  public url: any;
  public hideNav: boolean = true;
  private readonly _destroy = new Subject<void>();

  public adalConfig = {
    instance: 'https://login.microsoftonline.com/',
    cacheLocation: 'localStorage',
    tenant: environment.tenant,
    clientId: environment.clientId,
    redirectUri: document.location.origin + '/auth-callback',
    postLogoutRedirectUri: document.location.origin,
    endpoints: {
      // local
      'http://localhost:50734': '77d219e8-340c-442e-ae35-4c98d81ed1aa',
      // dev1
      'https://wapazewdmlit001champsapi.crb.apmoller.net': '77d219e8-340c-442e-ae35-4c98d81ed1aa',
      // dev2
      'https://wapazewdmlit001champsapi-staging.crb.apmoller.net': '77d219e8-340c-442e-ae35-4c98d81ed1aa',
      // st
      'https://wapazewtmlit001champsapi.crb.apmoller.net': '77d219e8-340c-442e-ae35-4c98d81ed1aa',
      // sit
      'https://wapazewtmlit002champsapi.crb.apmoller.net': '1b67a8ac-36c0-4cc7-8ecd-655fd68420f9',
      // edu
      // 'https://wapazewumlit001champsapi.crb.apmoller.net': '9d9c9aec-9a7a-4aed-adc6-9b82508bf2a3',
      'https://wapazewumlit001champsapi.crb.apmoller.net': '1b67a8ac-36c0-4cc7-8ecd-655fd68420f9',
      // preprod
      'https://wapazewrmlit001champsapi.crb.apmoller.net': 'a39a4ce6-c009-4fc5-83f1-16e98af2b17b',
    },
  };

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, public npsFeedbackService: NpsFeedbackService ,titleService: Title, router: Router, public msal: MsalService, public auth: AuthService, private ft: FeatureToggleService, private msalBroadcastService: MsalBroadcastService, private msalLoginRdrtService: MsalLoginRedirectService) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const title = this.getDeepestTitle(router.routerState.snapshot.root);
          if (title === ' : VesselsList') {
            titleService.setTitle('CHAMPS');
          }
          if (title === ' : Fixtures') {
            titleService.setTitle('CHAMPS');
          } else if (title === ' : Services') {
            titleService.setTitle('CHAMPS');
          } else if (title === ' : Charter Requests') {
            titleService.setTitle('CHAMPS');
          } else if (title === ' : Dummy Overview') {
            titleService.setTitle('CHAMPS');
          } else {
            titleService.setTitle('CHAMPS' + (title ? title : ''));
          }
          return title;
        })
      )
      .subscribe();

    this.msal.initialize();
    // this.msal.instance.handleRedirectPromise();
    if (this.msal.instance.getAllAccounts().length > 0 || this.auth.BYPASS_AUTHENTICATION) {
      this.hideNav = false;
    } else {
      this.hideNav = true;
    }
    this.auth.authToken = this.getToken();
    this.msalBroadcastService.inProgress$.pipe(filter((interactionStatus: InteractionStatus) =>
      interactionStatus == InteractionStatus.None), takeUntil(this._destroy)).subscribe(x => {
        this.isUserLoggedIn = this.msal.instance.getAllAccounts().length > 0;
        this.msalLoginRdrtService.isUserLoggedIn.next(this.isUserLoggedIn);
        this.userProfileDetails = this.msal.instance.getAllAccounts()[0]?.idTokenClaims;
        this.npsFeedbackService.validateSubmissionDate().subscribe(reminderFlag => {
          this.sus_nps_validation = reminderFlag; 
        });
      }
      );
    if (this.msal.instance.getAllAccounts()[0]?.idTokenClaims?.roles !== undefined) { //insert and update will not happen
      const res = this.auth.autoBlockUser();
      res.subscribe((data) => {
        if (data) {
          // console.log(JSON.stringify(data));
        }
      });
    }
    this.ft.getFeatureToggleList();
  }

  public ngOnInit() {
    console.log('Angular Version 17')
    this.msal.instance.setActiveAccount(this.msal.instance.getAllAccounts()[0]);
    this.auth.roles = environment.byPassAuthentication === 'true' ? ['L3'] : this.msal.instance.getAllAccounts()[0]?.idTokenClaims?.roles;
    this.auth.userProfile = environment.byPassAuthentication === 'true' ? {roles: ['L3'] } : this.msal.instance.getAllAccounts()[0]?.idTokenClaims;
    this.url = document.URL;
    const newURL = this.url.split('/');
    if (newURL[3] === 'fixtures' && (newURL[4] === 'recap' || newURL[4] === 'additionals')) {
      this.hideNav = true;
    }
    if (newURL[4] === 'change-log' || newURL[4] === 'changelog') {
      this.hideNav = true;
    }
    if (newURL[3] === 'owners' && newURL[4] === 'changeLogOwner') {
      this.hideNav = true;
    }
    if (newURL[3] === 'average-bunker' && newURL[4] === 'changeLogAvgBunker') {
      this.hideNav = true;
    }
    if (newURL[3] === 'health') {
      this.hideNav = true;
    }
  }

  /** funtions for authentication */
  public getToken() {
    return this.msal.instance.getTokenCache();
  }

  public signOut(): void {
    this.msal.logoutRedirect({ postLogoutRedirectUri: environment.postLogoutUrl }); //working
  }
  /** end */

  public signOutHandler(event) {
    if (event) {
      this.signOut();
    }
  }

  public login() {
    if (this.msalGuardConfig.authRequest) {
      this.msal.loginRedirect({
        ...this.msalGuardConfig.authRequest
      } as RedirectRequest);
    }
    else {
      this.msal.loginPopup();
    }
  }

  public ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title = routeSnapshot.data ? routeSnapshot.data['title'] : '';
    title = title ? ' : ' + title : title;
    if (routeSnapshot.firstChild) {
      title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
      if (title == ' : Vessels') {
        title = ':' + ' ' + 'V' + '-' + ' ' + routeSnapshot.data?.details?.vslVesselName;
      }
    }
    return title;
  }
}
